export const BASE_COLORS = {
  primary: "#FF5757",
  primaryLight: "rgba(255,87,87, 0.29)",
  secondary: "#0F0F0F",
  black: "#000000",
  white: "white",
  red: "#FF5757",
  grey: "#C1C1C1",
  lightGrey: "#D9D9D9",
  primaryTransparent: "#FF575730",
  primaryTransparentLight: "#FF575712",
  semiTransparentBlack: "#000000AA",
  blackShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.1)",
  pending: "#F79E1B",
  lightblackShadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.185)",
  greyShadow: "0px 12px 24px -4px #919EAB20",
  success: "#2E844A",
  error: "#B90616",
  transparent: "none",
  translucent: "rgba(255, 255, 255, 0.2)",
  grey2: "#B1B1B1",
  green: "#4ECB71",
  transparentBlack: "#00000033",
  lightGreyTransparent: "#D9D9D960",
  primaryLightFontColor: "#ff7f79",
  primaryLightTransparent: "rgba(255,87,87, 0.05)",
  bg2: "",
  background: "",
  foreground: "",
};

export const LIGHT_COLORS = {
  ...BASE_COLORS,
  bg2: "#F4F6F9",
  background: "#FFFFFF",
  error: "#D84E4E",
  foreground: "#000000",
};

export const DARK_COLORS = {
  ...BASE_COLORS,
  bg2: "#000000",
  background: "#000000",
  error: "#DC5F5F",
  foreground: "#FFFFFF",
};

export type AppColors = keyof typeof BASE_COLORS;



